import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Policy } from "../components/Policy";
import { policyPageHeroImage } from "../images";

const PolicyPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${policyPageHeroImage})`}
      heroOverlayLeadElement="Policy"
      heroOverlaySecondaryElement="Policy"
    >
      <Policy />
    </PageLayout>
  );
};

export default PolicyPage;
